import React, { useEffect, useRef, useState } from "react"
import AccountLayout from "../../../components/accountLayout"
import AccountManager from "../../../components/accountManager"

const AccountManagerPage = () => {
  const [isDisabled, setIsDisabled] = useState(true)
  const formInputRef = useRef({})

  useEffect(() => {
    formInputRef.current = { ...formInputRef.current, formLevel: 6 }
  }, [])

  return (
    <AccountLayout
      title="Add an account manager"
      to="/account-details/single-professional-host/account-manager-review"
      formInput={formInputRef.current}
      isDisabled={isDisabled}
    >
      <AccountManager formInputRef={formInputRef} setIsDisabled={setIsDisabled} />
    </AccountLayout>
  )
}

export default AccountManagerPage
